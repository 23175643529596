<template>
  <div class="abilityDetails">
    <!-- 标题 -->
    <div class="title">我的健康</div>
    <div class="content">
      <div class="introduce">
        <div class="first">
          <div>姓名:{{ userInfo.userName }}</div>
          <div>性别:{{ userInfo.gender }}</div>
          <div>出生年月日:{{ userInfo.birthday }}</div>
        </div>
        <div class="first">
          <div>身高:{{ userInfo.height }}cm</div>
          <div>体重:{{ userInfo.weight }}kg</div>
          <div>出生城市:{{ userInfo.residence }}</div>
        </div>
        <div class="first">
          <div>出生地:{{ userInfo.birthPlace }}</div>
        </div>
      </div>
      <div class="line"></div>
      <div>
        <div class="substance">
          <titleNav :text="'主要健康问题'"></titleNav>
        </div>
        <!-- 主要健康问题 -->
        <div class="marg_left">
          <slide :type="'ordinary'" :slideItem="slideItem"></slide>
        </div>
      </div>
      <div class="line"></div>
      <div>
        <div class="substance">
          <titlenav :text="'各大系统健康风险矩阵分析'"></titlenav>
        </div>
        <div class="marg_left">
          <slide :type="'ordin'" :slideItem="slideItem1"></slide>
        </div>
      </div>
      <div class="line"></div>

      <div class="substance">
        <titleNav :text="'器官功能'"></titleNav>
      </div>
      <div class="apparatus">
        <div class="fun">器官功能预警</div>
        <div class="rate" v-for="(item, indexO) in organFunction" :key="indexO">
          <van-circle v-model="currentRate" :text="item.showOrgan" :stroke-width="80" layer-color="#ebedf0" :color="
            Number(item.caseValue) <= 0.425
              ? '#B21E23'
              : (Number(item.caseValue) > 0.425 && Number(item.caseValue)) <=
                0.9
                ? '#E8EA70'
                : Number(item.caseValue) > 0.9
                  ? '#88BC5C'
                  : ''
          " :rate="Number(item.caseValue) * 100" size="90px" />
          <div class="pathogen">{{ item.caseDescription }}</div>
        </div>
      </div>
      <titleNav :text="'健康数据'" style="padding: 10px 25px 0"></titleNav>
      <div class="substance">
        <!-- 循环各个echars -->
        <div class="heart" v-for="(item, indexW) in watchFunction" :key="indexW">
          <div class="heartrate">{{ item.titleName }}</div>
          <div class="echart">
            <div :id="
              item.watchType == 1
                ? 'mainSleep'
                : item.watchType == 2
                  ? 'main'
                  : item.watchType == 3
                    ? 'bloodOxygen'
                    : item.watchType == 4
                      ? 'mains'
                      : 'BrokenLine'
            " class="histogram"></div>
          </div>
          <div class="near" style="position: relative">
            <div @click="timeBtn(indexD)" :class="{ times1: curId == indexD }" style="padding: 5px 10px"
              v-for="(item, indexD) in dataTime" :key="indexD">
              {{ item.time }}
            </div>
          </div>
          <div class="tips" v-if="item.watchType == 3">
              <div class="less"> ＜90% </div>
              <div class="greater">90%-100%</div>
          </div>
          <div class="tips" v-if="item.watchType == 4">
              <div class="less">舒张压</div>
              <div class="greater">收缩压</div>
          </div>
          <div class="Tips" v-if="item.watchType == 2">
            <div class="heigh">偏高80-90</div>
            <div class="medium">中等60-79</div>
            <div class="normal">正常30-59</div>
            <div class="relax">放松1-29</div>
          </div>
          <div style="margin-top: 30px">
            <div class="aWeek">
              <div class="minimum" v-for="index1 in 2" :key="index1 + '0'" :style="{
                'border-color':
                  index1 == 1
                    ? '#9566ff'
                    : index1 == 2
                      ? '#ff9180'
                      : '#00c8c8',
              }" v-show="item.watchType == 1">
                <div style="display: flex; align-items: center">
                  <div :style="{ background: '#9566ff' }" class="data-content" v-if="index1 == 1"></div>
                  <div :style="{ background: '#ff9180' }" class="data-content" v-if="index1 == 2"></div>
                  <div v-if="index1 == 1" style="margin: 0 8px 0 8px">
                    最低值
                  </div>
                  <div v-if="index1 == 2" style="margin: 0 8px 0 8px">
                    最高值
                  </div>
                </div>
                <div>
                  <span v-if="index1 == 1" style="font-size: 18px; font-weight: bold; color: #000">{{
                    item.minValue
                  }}</span>
                  <span v-if="index1 == 2" style="font-size: 18px; font-weight: bold; color: #000">{{
                    item.maxValue
                  }}</span>
                  <span>次/分</span>
                </div>
              </div>
              <div class="minimum2" v-for="index2 in 2" :key="index2 + '1'" :style="{
                'border-color':
                  index2 == 1
                    ? '#9566ff'
                    : index2 == 2
                      ? '#ff9180'
                      : '#00c8c8',
              }" v-show="item.watchType == 2">
                <div style="display: flex; align-items: center">
                  <div :style="{ background: '#9566ff' }" class="data-content" v-if="index2 == 1"></div>
                  <div :style="{ background: '#ff9180' }" class="data-content" v-if="index2 == 2"></div>
                  <div v-if="index2 == 1" style="margin: 0 8px 0 8px">
                    当日平均值
                  </div>
                  <div v-if="index2 == 2" style="margin: 0 8px 0 8px">
                    压力范围
                  </div>
                </div>
                <div>
                  <span v-if="index2 == 1" style="font-size: 18px; font-weight: bold; color: #000">{{
                    item.avgValue
                  }}</span>
                  <span v-if="index2 == 2" style="font-size: 18px; font-weight: bold; color: #000">{{
                    item.minValue
                  }}~{{ item.maxValue }}</span>
                </div>
              </div>
              <div class="minimum3" v-for="index3 in 2" :key="index3 + '2'" :style="{
                'border-color':
                  index3 == 1
                    ? '#9566ff'
                    : index3 == 2
                      ? '#ff9180'
                      : '#00c8c8',
              }" v-show="item.watchType == 3">

                <div style="display: flex; align-items: center">
                  <div :style="{ background: '#9566ff' }" class="data-content" v-if="index3 == 1"></div>
                  <div :style="{ background: '#ff9180' }" class="data-content" v-if="index3 == 2"></div>
                  <div v-if="index3 == 1" style="margin: 0 8px 0 8px">
                    最低值
                  </div>
                  <!-- <div v-if="index3 == 2" style="margin: 0 8px 0 8px">
                    平均值
                  </div> -->
                  <div v-if="index3 == 2" style="margin: 0 8px 0 8px">
                    最高值
                  </div>
                </div>
                <div>
                  <span v-if="index3 == 1" style="font-size: 18px; font-weight: bold; color: #000">{{
                    item.minValue
                  }}</span>
                  <!-- <span v-if="index3 == 2" style="font-size: 18px; font-weight: bold; color: #000">{{
                    item.avgValue
                  }}</span> -->
                  <span v-if="index3 == 2" style="font-size: 18px; font-weight: bold; color: #000">{{
                    item.maxValue
                  }}</span>
                  <span>次/分</span>
                </div>
              </div>
              <div class="minimum4" v-for="index4 in 2" :key="index4 + '3'" :style="{
                'border-color':
                  index4 == 1
                    ? '#9566ff'
                    : index4 == 2
                      ? '#ff9180'
                      : '#00c8c8',
              }" v-show="item.watchType == 4">
                <div style="display: flex; align-items: center">
                  <div :style="{ background: '#9566ff' }" class="data-content" v-if="index4 == 1"></div>
                  <div :style="{ background: '#ff9180' }" class="data-content" v-if="index4 == 2"></div>
                  <div v-if="index4 == 1" style="margin: 0 8px 0 8px">
                    最低值
                  </div>
                  <div v-if="index4 == 2" style="margin: 0 8px 0 8px">
                    最高值
                  </div>
                </div>
                <div>
                  <span v-if="index4 == 1" style="font-size: 18px; font-weight: bold; color: #000">{{
                    item.minValue
                  }}</span>
                  <span v-if="index4 == 2" style="font-size: 18px; font-weight: bold; color: #000">{{
                    item.maxValue
                  }}</span>
                </div>
              </div>
              <div class="minimum5" v-for="index5 in 2" :key="index5 + '4'" :style="{
                'border-color':
                  index5 == 1
                    ? '#9566ff'
                    : index5 == 2
                      ? '#ff9180'
                      : '#00c8c8',
              }" v-show="item.watchType == 5">
                <div style="display: flex; align-items: center">
                  <div :style="{ background: '#9566ff' }" class="data-content" v-if="index5 == 1"></div>
                  <div :style="{ background: '#ff9180' }" class="data-content" v-if="index5 == 2"></div>
                  <div v-if="index5 == 1" style="margin: 0 8px 0 8px">深睡</div>
                  <div v-if="index5 == 2" style="margin: 0 8px 0 8px">浅睡</div>
                </div>

                <div>
                  <span v-if="index5 == 1" style="font-size: 18px; font-weight: bold; color: #000">{{
                    item.minValue
                  }}</span>
                  <span v-if="index5 == 2" style="font-size: 18px; font-weight: bold; color: #000">{{
                    item.avgValue
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 30px;"></div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  VisualMapComponent,
  LegendComponent,
} from "echarts/components";
import titleNav from "../titlenav.vue";
import slide from "../../../components/slide";
import data from "@/components/data";
import { LineChart, BarChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { mapGetters } from "vuex";
import { GetHealthPageInfo } from "@/api/index";
import { ok } from "assert";
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  VisualMapComponent,
  LineChart,
  BarChart,
  CanvasRenderer,
  UniversalTransition,
  LegendComponent,
]);
export default {
  name: "MyHealthSL",
  components: {
    titleNav,
    slide,
  },
  data() {
    return {
      category: true,
      curId: 0,
      slideItem: data.slideItem18,
      slideItem1: data.slideItem19,
      currentRate: 100,
      currentRate1: 80,
      currentRate2: 90,
      dataList: {
        name: "胃",
      },
      current: `${new Date().getHours()}:${new Date().getMinutes()}`,
      userInfo: "",
      organFunction: "",
      dataTime: [
        {
          time: "近1周",
          dataValue: [
            {
              title: "最低值",
              frequency: 45,
              color: "#9566ff",
              type: 0,
              maxValue: 1,
              minValue: 2,
              avgValue: 3,
            },
            {
              title: "平均值",
              frequency: 43,
              color: "#ff9180",
              type: 1,
              maxValue: 1,
              minValue: 2,
              avgValue: 3,
            },
            {
              title: "最高值",
              frequency: 45,
              color: "#00c8c8",
              type: 2,
              maxValue: 1,
              minValue: 2,
              avgValue: 3,
            },
          ],
        },
        {
          time: "近1月",
          dataValue: [
            {
              title: "最低值",
              frequency: 56,
              color: "#9566ff",
              type: 0,
              maxValue: 1,
              minValue: 2,
              avgValue: 3,
            },
            {
              title: "平均值",
              frequency: 43,
              color: "#ff9180",
              type: 1,
              maxValue: 1,
              minValue: 2,
              avgValue: 3,
            },
            {
              title: "最高值",
              frequency: 45,
              color: "#00c8c8",
              type: 2,
              maxValue: 1,
              minValue: 2,
              avgValue: 3,
            },
          ],
        },
        {
          time: "近3月",
          dataValue: [
            {
              title: "最低值",
              frequency: 45,
              color: "#9566ff",
              type: 0,
              maxValue: 1,
              minValue: 2,
              avgValue: 3,
            },
            {
              title: "平均值",
              frequency: 43,
              color: "#ff9180",
              type: 1,
              maxValue: 1,
              minValue: 2,
              avgValue: 3,
            },
            {
              title: "最高值",
              frequency: 45,
              color: "#00c8c8",
              type: 2,
              maxValue: 1,
              minValue: 2,
              avgValue: 3,
            },
          ],
        },
        {
          time: "近6月",
          dataValue: [
            {
              title: "最低值",
              frequency: 45,
              color: "#9566ff",
              type: 0,
              maxValue: 1,
              minValue: 2,
              avgValue: 3,
            },
            {
              title: "平均值",
              frequency: 43,
              color: "#ff9180",
              type: 1,
              maxValue: 1,
              minValue: 2,
              avgValue: 3,
            },
            {
              title: "最高值",
              frequency: 45,
              color: "#00c8c8",
              type: 2,
              maxValue: 1,
              minValue: 2,
              avgValue: 3,
            },
          ],
        },
        {
          time: "近1年",
          dataValue: [
            {
              title: "最低值",
              frequency: 45,
              color: "#9566ff",
              type: 0,
              maxValue: 1,
              minValue: 2,
              avgValue: 3,
            },
            {
              title: "平均值",
              frequency: 43,
              color: "#ff9180",
              type: 1,
              maxValue: 1,
              minValue: 2,
              avgValue: 3,
            },
            {
              title: "最高值",
              frequency: 45,
              color: "#00c8c8",
              type: 2,
              maxValue: 1,
              minValue: 2,
              avgValue: 3,
            },
          ],
        },
      ],
      tipsData: [
        {
          title: "深睡",
          bagcolor: "#9A18E8",
          hour: "6",
          minute: "30",
        },
        {
          title: "浅睡",
          bagcolor: "#E6B3F5",
          hour: "2",
          minute: "30",
        },
        {
          title: "清醒",
          bagcolor: "#FCB334",
          hour: "4",
          minute: "20",
        },
      ],
      chooseIndex:0,//选择了哪个时间
      watchFunction: "",
      xTime: [],
      yData: [],
      nweXcode: [],
      xData0: [],
      yData0: [],
      xData1: [],
      yData1: [],
      xData2: [],
      yData2: [],
      xData3: [],
      yData3: [],
      xData4: [],
      yData4: [],
      diastolic: []
    };
  },
  methods: {
    // 心率
    initializationBrokenLineSleep() {
      if (document.getElementById("mainSleep") == null) {
        return;
      }
      echarts.use([
        TitleComponent,
        TooltipComponent,
        GridComponent,
        VisualMapComponent,
        LineChart,
        CanvasRenderer,
        UniversalTransition,
      ]);
      var chartDom = document.getElementById("mainSleep");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "10%",
          right: "15%",
          bottom: "10%",
          top: "20%",
          width: "auto",
          height: "auto",
          containLabel: true,
          shadowColor: "rgba(0, 0, 0, 0.5)",
          shadowBlur: 10,
        },
        xAxis: {
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          type: "category",
          data: this.xData0,
          axisLabel: {
            interval: 0,
            margin: 15,
          },
        },
        yAxis: {
          axisLine: {
            show: false, // 是否显示坐标轴
          },
          axisTick: {
            show: false, // 是否显示坐标轴刻度
          },
          type: "value",
          scale: true,
          max: 300,
          min: 0,
          splitNumber: 3,
        },
        series: [
          {
            type: "line",
            symbol: "none",
            sampling: "lttb",
            itemStyle: {
              color: "#FF9281",
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#FF9180",
                },
                {
                  offset: 1,
                  color: "#FFC9C0",
                },
              ]),
            },
            data: this.yData0,
          },
        ],
      };
      option && myChart.setOption(option);
    },
    // 血压
    bloodConfig() {
      if (document.getElementById("mains") == null) {
        return;
      }
      // 基于准备好的dom，初始化echarts实例
      var chartDom = document.getElementById("mains");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "10%",
          right: "5%",
          bottom: "3%",
          containLabel: true,
        },
        color: ["#9566FF", "#FFC3B8"],
        legend: {
          data: this.xData3,
        },
        xAxis: [
          {
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            type: "category",
            data: this.xData3,
            axisLabel: {
              interval: 0,
              margin: 15,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            max: 200,
            min: 0,
            splitNumber: 4,
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
              },
            },
          },
        ],
        series: [
          // 第一条线
          {
            name: "舒张压",
            type: "line",
            showSymbol: false, // 小圆点是否显示
            emphasis: {
              focus: "series",
            },
            data: this.diastolic,
            symbolSize: 0, // 拐点的颜色
            areaStyle: {
              // 区域样式
              type: "default",
              opacity: 0.3,
            },
            itemStyle: {
              // 图形区域颜色
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(149,102,255,0.6)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(149,102,255,1)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
          // 第二条线
          {
            name: "收缩压",
            type: "line",
            showSymbol: false,
            emphasis: {
              focus: "series",
            },
            color: "#9566ff",
            areaStyle: {
              // 区域样式
              type: "default",
              opacity: 0.2,
            },
            stack: null,
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(255,146,129,0.9)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(255,146,129,1)", // 100% 处的颜色
                  },
                ],
                global: true, // 缺省为 false
              },
            },
            data: this.yData3,
          },
        ],
      };
      option && myChart.setOption(option);
    },
    // 压力
    pressureConfig() {
      if (document.getElementById("main") == null) {
        return;
      }
      // 基于准备好的dom，初始化echarts实例
      var chartDom = document.getElementById("main");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "70px",
          top: "60px",
          right: "60px",
          bottom: "10%",
        },
        xAxis: {
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          type: "category",
          data: this.xData1,
          axisLabel: {
            margin: 10,
            interval: 0,
          },
        },
        yAxis: {
          type: "value",
          max: 100,
          min: 0,
          interval: 20,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              // y轴线的样式
              type: "dashed",
              // color:'#969798',
            },
          },
        },
        series: [
          {
            data: this.yData1,
            type: "bar",
            barWidth: 4,
            itemStyle: {
              color: (arg) => {
                if (arg.value < 30) {
                  return "#1496B8";
                } else if (arg.value >= 30 && arg.value < 59) {
                  return "#7DD1DF";
                } else if (arg.value >= 59 && arg.value < 79) {
                  return "#EDDD80";
                } else if (arg.value >= 79 && arg.value < 100) {
                  return "#DB7D2B";
                }
              },
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
    // 睡眠
    initializationBrokenLine() {
      if (document.getElementById("BrokenLine") == null) {
        return;
      }
      echarts.use([
        TitleComponent,
        TooltipComponent,
        GridComponent,
        VisualMapComponent,
        LineChart,
        CanvasRenderer,
        UniversalTransition,
      ]);
      var chartDom = document.getElementById("BrokenLine");
      var myChart = echarts.init(chartDom);
      var option;
      const valueList = this.yData4;
      option = {
        visualMap: [
          {
            show: false,
            type: "continuous",
            seriesIndex: 0,
            min: 0,
          },
        ],
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          type: "category",
          boundaryGap: false,
          data: this.xData4,
        },
        yAxis: {
          type: "value",
          show: true,
          data: this.yData,
        },

        series: [
          {
            type: "line",
            showSymbol: false,
            data: valueList,
          },
        ],
      };

      option && myChart.setOption(option);
    },
    //血氧饱和度
    initializationBlood() {
      if (document.getElementById("bloodOxygen") == null) {
        return;
      }
      echarts.use([
        TitleComponent,
        TooltipComponent,
        GridComponent,
        VisualMapComponent,
        LineChart,
        CanvasRenderer,
        UniversalTransition,
      ]);
      var chartDom = document.getElementById("bloodOxygen");
      var myChart = echarts.init(chartDom);
      var option;
      let series = [];
      for (let index = 0; index < 18; index++) {
        series.push({
          type: "bar",
          itemStyle: { color: "#22BE96" },
        });
      }
      const data = this.yData2
      option = {
        legend: {},
        tooltip: {},
        xAxis: {
          type: "category",
          show: false,
          data: this.xData2,
          splitLine: { show: false }, //去除网格线
          data: this.xData2,
        },
        xAxis: {
          axisLine: {
            show: false, // 是否显示坐标轴
          },
          axisTick: {
            show: false, //是否显示坐标轴刻度
          },
          type: "category",
          data: this.xData2,
        },
        yAxis: {
          type: "value",
          max: 120,
          min: 0,
          interval: 20,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              // y轴线的样式
              type: "dashed",
              // color:'#969798',
            },
          },
          splitNumber: 2,
          axisLabel: {
            formatter: this.yData,
          },
        },
        grid: {
          left: "10%",
          bottom: "3%",
          containLabel: true,
        },
        series: {
          data: data,
          type: "bar",
          barWidth: 30,
          itemStyle: {
            color: function (params) {
              if (params.value < 90) {
                return "#D330FF";
              } else {
                return "#22BE96";
              }
            },
          },
        },
      };
      option && myChart.setOption(option);
    },
    GetHealthPageInfo(indexD) {
      let that = this;
      this.$toast.loading({
        message: "获取信息中",
      });
      console.log("999")
      GetHealthPageInfo({
        userId: this.userid,
        timeType: !indexD ? 1 : indexD,
      }).then((res) => {
        
        const { data } = res;
        if (data) {
          
          if(data == null){
            this.$toast('上传成功');
            return
          }
          this.slideItem = data.quesFunction;
          this.userInfo = data.userInfo;
          this.organFunction = data.organFunction;
          this.watchFunction = data.watchFunction;
          console.log("data.watchFunction", data.watchFunction);
          for (let i = 0; i < data.watchFunction.length; i++) {
            if (i == 0) {
              let a = this.handleSelectionChange(
                data.watchFunction[i].watchDetailDataModels
              );
              this.xData0 = a.xTime;
              this.yData0 = a.yData;
            } else if (i == 1) {
              let a = this.handleSelectionChange(
                data.watchFunction[i].watchDetailDataModels
              );
              this.xData1 = a.xTime;
              this.yData1 = a.yData;
            } else if (i == 2) {
              let a = this.handleSelectionChange(
                data.watchFunction[i].watchDetailDataModels
              );
              this.xData2 = a.xTime;
              this.yData2 = a.yData;
            } else if (i == 3) {
              let a = this.handleSelectionChange(
                data.watchFunction[i].watchDetailDataModels
              );
              this.xData3 = a.xTime;
              this.yData3 = a.yData;
              this.diastolic = a.nweXcode
              console.log('a', a)
            } else if (i == 4) {
              let a = this.handleSelectionChange(
                data.watchFunction[i].watchDetailDataModels
              );

              this.xData4 = a.xTime;
              this.yData4 = a.yData;

            }
            for (let j = 0; j < this.dataTime.length; j++) {
              let dataTime = this.dataTime[j].dataValue;
              for (let d = 0; d < dataTime.length; d++) {
                this.$nextTick(() => {
                  this.initializationBrokenLine();
                  this.initializationBrokenLineSleep();
                  this.bloodConfig();
                  this.pressureConfig();
                  this.initializationBlood();
                });
              }
            }
          }
        } else {
          this.$toast('暂无数据');
        }
      });
    },
    //  数组对象转数组方法
    handleSelectionChange(val) {
      //当前选中勾选的数组对象
      let arrXcode = []; //定义X轴数组
      let arrYcode = []; //定义Y轴数组
      let nweXcode = []; //定义舒张压数组
      val.map((item) => {
        //遍历当前的数组对象
        arrXcode.push(item.timeDate);
        arrYcode.push(item.heartRateValue); //将当前某个值productPushCode取出来放入数组中
        nweXcode.push(item.heartRateValue1)
        return item.timeDate, item.heartRateValue, item.heartRateValue1;
      })
        .join(",")
        .split(",");
        if(this.chooseIndex==0||this.chooseIndex==1){
          for (let i = 0; i < arrXcode.length; i++) {
          arrXcode[i] = arrXcode[i].substr(5, 10);
      }
        }
      
      this.xTime = arrXcode.reverse();
      this.yData = arrYcode;
      this.nweXcode = nweXcode
      let a = {
        xTime: arrXcode,
        yData: arrYcode,
        nweXcode: nweXcode
      };
      return a;
    },
    timeBtn(indexD) {
      console.log(indexD)
      this.chooseIndex = indexD
      this.curId = indexD;
      var indexD = Number(indexD) + 1;
      this.GetHealthPageInfo(indexD);
    },
  },
  mounted() {
    // console.log(this.dataTime)
    this.GetHealthPageInfo();
  },
  computed: {
    ...mapGetters(["userid"]),
    text() {
      return this.dataList.name;
    },
  },
};
</script>
<style lang="scss" scoped>
.abilityDetails {
  height: 70%;
  background: url("../../../assets/background.png");
  background-size: 100%;
  background-color: #efefef;
  color: #4f4f4f;
  font-size: 22px;
  position: relative;

  .title {
    text-align: center;
    margin-top: -43px;
  }

  .content {
    width: 100%;
    background: #efefef;
    color: #808080;
    position: absolute;
    margin-top: 100px;

    .introduce {
      width: 350px;
      height: 120px;
      border-radius: 10px;
      background: #ffffff;
      margin: -80px auto 0;
      color: #737373;

      .first {
        display: flex;
        font-size: 14px;
        padding: 15px 0 0 0;

        div {
          margin-left: 15px;
        }
      }
    }

    .line {
      border-bottom: 1px solid #d0cedb;
      width: 90%;
      margin: 20px auto 10px;
    }

    .substance {
      width: 100%;
      display: flex;
      overflow-x: scroll;
      width: 100%;
      // padding: 10px 25px 0;
    }

    .marg_left {
      width: 100%;
      padding-left: 25px;
    }

    .apparatus {
      width: 90%;
      height: 400px;
      margin: 10px auto;
      background-color: #ffffff;
      border-radius: 10px;

      .fun {
        font-size: 15px;
        padding: 15px 30px;
        position: relative;
      }

      .fun::before {
        content: "";
        position: absolute;
        top: 17px;
        left: 16px;
        width: 5px;
        height: 17px;
        border-radius: 5px;
        background-color: #ff9580;
      }

      .rate {
        display: flex;
        margin: 10px;
        font-size: 14px;

        .pathogen {
          width: 220px;
        }

        /deep/.van-circle {
          margin: 0 20px;
        }
      }
    }

    .heart {
      // height: 650px;
      background-color: #ffffff;
      border-radius: 10px;
      margin: 0 20px;
      padding: 30px 10px;

      .heartrate {
        text-align: center;
        // padding-top: 30px;
      }

      .time {
        color: #868686;
        text-align: center;
        margin-top: 20px;

        .current {
          font-size: 20px;
          font-weight: 700;
          text-align: center;
        }
      }

      .echart {
        width: 100%;
        position: relative;

        .histogram {
          width: 100%;
          height: 240px;
        }

        .histograms {
          width: 100%;
          height: 240px;
        }

        .timenode {
          width: 90%;
          display: flex;
          justify-content: space-evenly;
          position: absolute;
          bottom: 10px;
          right: 0;
          color: #808080;
          font-size: 15px;
        }
      }

      .percentage {
        display: flex;
        align-items: center;
        color: #858585;
        font-size: 15px;
        margin: 20px 115px;

        .num {
          color: #4f4f4f;
          font-size: 34px;
        }
      }

      .near {
        display: flex;
        justify-content: space-evenly;
        // margin-top: 50px !important;
        font-size: 15px;

        .times {
          width: 55px;
          padding: 8px;
          color: #868686;
        }

        .times1 {
          border-radius: 20px;
          text-align: center;
          background: #ffe6e2;
          color: #ff9180;
          padding: 10px 15px;
        }
      }

      .tipsNum {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        margin-top: 20px;

        .mini,
        .average,
        .max {
          width: 30%;
          height: 60px;
          padding: 5px 10px;
          border-radius: 5px;
          font-size: 10px;

          .title {
            display: flex;
            align-items: center;
            color: #808080;
            height: 50px;

            .tipicon {
              width: 5px;
              height: 10px;
              border-radius: 5px;
              margin-right: 5px;
            }
          }

          .text {
            color: #808080;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: -10px;

            .num {
              color: #282828;
              font-size: 20px;
            }
          }
        }

        .mini {
          border: 1px solid #d330ff;
          background: #fef8fe;

          .tipicon {
            background: #d330ff;
          }
        }

        .average {
          border: 1px solid #fcb334;
          background: #fffbf5;

          .tipicon {
            background: #fcb334;
          }
        }

        .max {
          border: 1px solid #2fbe95;
          background: #f4fbf9;

          .tipicon {
            background: #2fbe95;
          }
        }
      }
    }
  }
}

.aWeek {
  display: flex;
  justify-content: space-between;
  // margin: 20px;
}

.minimum {
  width: 140px;
  height: 52px;
  border: 1px solid #000;
  font-size: 9px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin: 0 10px;
}

.minimum2 {
  width: 140px;
  height: 52px;
  border: 1px solid #000;
  font-size: 9px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin: 0 10px;
}

.minimum3 {
  width: 140px;
  height: 52px;
  border: 1px solid #000;
  font-size: 9px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin: 0 10px;
}

.minimum4 {
  width: 140px;
  height: 52px;
  border: 1px solid #000;
  font-size: 9px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin: 0 10px;
}

.minimum5 {
  width: 140px;
  height: 52px;
  border: 1px solid #000;
  font-size: 9px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin: 0 10px;
}

.data-content {
  height: 10px;
  width: 5px;
}

.Tips {
  width: 100%;
  margin-top: 20px;
  margin-left: 8px;
  display: flex;
  justify-content: space-evenly;

  .heigh,
  .medium,
  .normal,
  .relax {
    position: relative;
    color: #666666;
    font-size: 13px;
  }

  .heigh::before,
  .medium::before,
  .normal::before,
  .relax::before {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 5px;
    margin-left: 2px;
  }

  .heigh::before {
    background: #db7d2b;
  }

  .medium::before {
    background: #eddd80;
  }

  .normal::before {
    background: #7dd1df;
  }

  .relax::before {
    background: #1496b8;
  }
}

.tips {
  width: 100%;
  display: flex;
  justify-content: space-around;

  .less,
  .greater {
    color: #808080;
    font-size: 14px;
    position: relative;
    margin: 20px 0 0 0;
  }

  .less::before,
  .greater::before {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 5px;
  }

  .less::before {
    background-color: rgb(149, 102, 255);
  }

  .greater::before {
    background-color: rgb(255, 145, 128);
  }
}
</style>
