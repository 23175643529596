var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"abilityDetails"},[_c('div',{staticClass:"title"},[_vm._v("我的健康")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"introduce"},[_c('div',{staticClass:"first"},[_c('div',[_vm._v("姓名:"+_vm._s(_vm.userInfo.userName))]),_c('div',[_vm._v("性别:"+_vm._s(_vm.userInfo.gender))]),_c('div',[_vm._v("出生年月日:"+_vm._s(_vm.userInfo.birthday))])]),_c('div',{staticClass:"first"},[_c('div',[_vm._v("身高:"+_vm._s(_vm.userInfo.height)+"cm")]),_c('div',[_vm._v("体重:"+_vm._s(_vm.userInfo.weight)+"kg")]),_c('div',[_vm._v("出生城市:"+_vm._s(_vm.userInfo.residence))])]),_c('div',{staticClass:"first"},[_c('div',[_vm._v("出生地:"+_vm._s(_vm.userInfo.birthPlace))])])]),_c('div',{staticClass:"line"}),_c('div',[_c('div',{staticClass:"substance"},[_c('titleNav',{attrs:{"text":'主要健康问题'}})],1),_c('div',{staticClass:"marg_left"},[_c('slide',{attrs:{"type":'ordinary',"slideItem":_vm.slideItem}})],1)]),_c('div',{staticClass:"line"}),_c('div',[_c('div',{staticClass:"substance"},[_c('titlenav',{attrs:{"text":'各大系统健康风险矩阵分析'}})],1),_c('div',{staticClass:"marg_left"},[_c('slide',{attrs:{"type":'ordin',"slideItem":_vm.slideItem1}})],1)]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"substance"},[_c('titleNav',{attrs:{"text":'器官功能'}})],1),_c('div',{staticClass:"apparatus"},[_c('div',{staticClass:"fun"},[_vm._v("器官功能预警")]),_vm._l((_vm.organFunction),function(item,indexO){return _c('div',{key:indexO,staticClass:"rate"},[_c('van-circle',{attrs:{"text":item.showOrgan,"stroke-width":80,"layer-color":"#ebedf0","color":Number(item.caseValue) <= 0.425
            ? '#B21E23'
            : (Number(item.caseValue) > 0.425 && Number(item.caseValue)) <=
              0.9
              ? '#E8EA70'
              : Number(item.caseValue) > 0.9
                ? '#88BC5C'
                : '',"rate":Number(item.caseValue) * 100,"size":"90px"},model:{value:(_vm.currentRate),callback:function ($$v) {_vm.currentRate=$$v},expression:"currentRate"}}),_c('div',{staticClass:"pathogen"},[_vm._v(_vm._s(item.caseDescription))])],1)})],2),_c('titleNav',{staticStyle:{"padding":"10px 25px 0"},attrs:{"text":'健康数据'}}),_c('div',{staticClass:"substance"},_vm._l((_vm.watchFunction),function(item,indexW){return _c('div',{key:indexW,staticClass:"heart"},[_c('div',{staticClass:"heartrate"},[_vm._v(_vm._s(item.titleName))]),_c('div',{staticClass:"echart"},[_c('div',{staticClass:"histogram",attrs:{"id":item.watchType == 1
              ? 'mainSleep'
              : item.watchType == 2
                ? 'main'
                : item.watchType == 3
                  ? 'bloodOxygen'
                  : item.watchType == 4
                    ? 'mains'
                    : 'BrokenLine'}})]),_c('div',{staticClass:"near",staticStyle:{"position":"relative"}},_vm._l((_vm.dataTime),function(item,indexD){return _c('div',{key:indexD,class:{ times1: _vm.curId == indexD },staticStyle:{"padding":"5px 10px"},on:{"click":function($event){return _vm.timeBtn(indexD)}}},[_vm._v(" "+_vm._s(item.time)+" ")])}),0),(item.watchType == 3)?_c('div',{staticClass:"tips"},[_c('div',{staticClass:"less"},[_vm._v(" ＜90% ")]),_c('div',{staticClass:"greater"},[_vm._v("90%-100%")])]):_vm._e(),(item.watchType == 4)?_c('div',{staticClass:"tips"},[_c('div',{staticClass:"less"},[_vm._v("舒张压")]),_c('div',{staticClass:"greater"},[_vm._v("收缩压")])]):_vm._e(),(item.watchType == 2)?_c('div',{staticClass:"Tips"},[_c('div',{staticClass:"heigh"},[_vm._v("偏高80-90")]),_c('div',{staticClass:"medium"},[_vm._v("中等60-79")]),_c('div',{staticClass:"normal"},[_vm._v("正常30-59")]),_c('div',{staticClass:"relax"},[_vm._v("放松1-29")])]):_vm._e(),_c('div',{staticStyle:{"margin-top":"30px"}},[_c('div',{staticClass:"aWeek"},[_vm._l((2),function(index1){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.watchType == 1),expression:"item.watchType == 1"}],key:index1 + '0',staticClass:"minimum",style:({
              'border-color':
                index1 == 1
                  ? '#9566ff'
                  : index1 == 2
                    ? '#ff9180'
                    : '#00c8c8',
            })},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(index1 == 1)?_c('div',{staticClass:"data-content",style:({ background: '#9566ff' })}):_vm._e(),(index1 == 2)?_c('div',{staticClass:"data-content",style:({ background: '#ff9180' })}):_vm._e(),(index1 == 1)?_c('div',{staticStyle:{"margin":"0 8px 0 8px"}},[_vm._v(" 最低值 ")]):_vm._e(),(index1 == 2)?_c('div',{staticStyle:{"margin":"0 8px 0 8px"}},[_vm._v(" 最高值 ")]):_vm._e()]),_c('div',[(index1 == 1)?_c('span',{staticStyle:{"font-size":"18px","font-weight":"bold","color":"#000"}},[_vm._v(_vm._s(item.minValue))]):_vm._e(),(index1 == 2)?_c('span',{staticStyle:{"font-size":"18px","font-weight":"bold","color":"#000"}},[_vm._v(_vm._s(item.maxValue))]):_vm._e(),_c('span',[_vm._v("次/分")])])])}),_vm._l((2),function(index2){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.watchType == 2),expression:"item.watchType == 2"}],key:index2 + '1',staticClass:"minimum2",style:({
              'border-color':
                index2 == 1
                  ? '#9566ff'
                  : index2 == 2
                    ? '#ff9180'
                    : '#00c8c8',
            })},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(index2 == 1)?_c('div',{staticClass:"data-content",style:({ background: '#9566ff' })}):_vm._e(),(index2 == 2)?_c('div',{staticClass:"data-content",style:({ background: '#ff9180' })}):_vm._e(),(index2 == 1)?_c('div',{staticStyle:{"margin":"0 8px 0 8px"}},[_vm._v(" 当日平均值 ")]):_vm._e(),(index2 == 2)?_c('div',{staticStyle:{"margin":"0 8px 0 8px"}},[_vm._v(" 压力范围 ")]):_vm._e()]),_c('div',[(index2 == 1)?_c('span',{staticStyle:{"font-size":"18px","font-weight":"bold","color":"#000"}},[_vm._v(_vm._s(item.avgValue))]):_vm._e(),(index2 == 2)?_c('span',{staticStyle:{"font-size":"18px","font-weight":"bold","color":"#000"}},[_vm._v(_vm._s(item.minValue)+"~"+_vm._s(item.maxValue))]):_vm._e()])])}),_vm._l((2),function(index3){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.watchType == 3),expression:"item.watchType == 3"}],key:index3 + '2',staticClass:"minimum3",style:({
              'border-color':
                index3 == 1
                  ? '#9566ff'
                  : index3 == 2
                    ? '#ff9180'
                    : '#00c8c8',
            })},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(index3 == 1)?_c('div',{staticClass:"data-content",style:({ background: '#9566ff' })}):_vm._e(),(index3 == 2)?_c('div',{staticClass:"data-content",style:({ background: '#ff9180' })}):_vm._e(),(index3 == 1)?_c('div',{staticStyle:{"margin":"0 8px 0 8px"}},[_vm._v(" 最低值 ")]):_vm._e(),(index3 == 2)?_c('div',{staticStyle:{"margin":"0 8px 0 8px"}},[_vm._v(" 最高值 ")]):_vm._e()]),_c('div',[(index3 == 1)?_c('span',{staticStyle:{"font-size":"18px","font-weight":"bold","color":"#000"}},[_vm._v(_vm._s(item.minValue))]):_vm._e(),(index3 == 2)?_c('span',{staticStyle:{"font-size":"18px","font-weight":"bold","color":"#000"}},[_vm._v(_vm._s(item.maxValue))]):_vm._e(),_c('span',[_vm._v("次/分")])])])}),_vm._l((2),function(index4){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.watchType == 4),expression:"item.watchType == 4"}],key:index4 + '3',staticClass:"minimum4",style:({
              'border-color':
                index4 == 1
                  ? '#9566ff'
                  : index4 == 2
                    ? '#ff9180'
                    : '#00c8c8',
            })},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(index4 == 1)?_c('div',{staticClass:"data-content",style:({ background: '#9566ff' })}):_vm._e(),(index4 == 2)?_c('div',{staticClass:"data-content",style:({ background: '#ff9180' })}):_vm._e(),(index4 == 1)?_c('div',{staticStyle:{"margin":"0 8px 0 8px"}},[_vm._v(" 最低值 ")]):_vm._e(),(index4 == 2)?_c('div',{staticStyle:{"margin":"0 8px 0 8px"}},[_vm._v(" 最高值 ")]):_vm._e()]),_c('div',[(index4 == 1)?_c('span',{staticStyle:{"font-size":"18px","font-weight":"bold","color":"#000"}},[_vm._v(_vm._s(item.minValue))]):_vm._e(),(index4 == 2)?_c('span',{staticStyle:{"font-size":"18px","font-weight":"bold","color":"#000"}},[_vm._v(_vm._s(item.maxValue))]):_vm._e()])])}),_vm._l((2),function(index5){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.watchType == 5),expression:"item.watchType == 5"}],key:index5 + '4',staticClass:"minimum5",style:({
              'border-color':
                index5 == 1
                  ? '#9566ff'
                  : index5 == 2
                    ? '#ff9180'
                    : '#00c8c8',
            })},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(index5 == 1)?_c('div',{staticClass:"data-content",style:({ background: '#9566ff' })}):_vm._e(),(index5 == 2)?_c('div',{staticClass:"data-content",style:({ background: '#ff9180' })}):_vm._e(),(index5 == 1)?_c('div',{staticStyle:{"margin":"0 8px 0 8px"}},[_vm._v("深睡")]):_vm._e(),(index5 == 2)?_c('div',{staticStyle:{"margin":"0 8px 0 8px"}},[_vm._v("浅睡")]):_vm._e()]),_c('div',[(index5 == 1)?_c('span',{staticStyle:{"font-size":"18px","font-weight":"bold","color":"#000"}},[_vm._v(_vm._s(item.minValue))]):_vm._e(),(index5 == 2)?_c('span',{staticStyle:{"font-size":"18px","font-weight":"bold","color":"#000"}},[_vm._v(_vm._s(item.avgValue))]):_vm._e()])])})],2)])])}),0),_c('div',{staticStyle:{"height":"30px"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }